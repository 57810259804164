import { useCallback, useEffect, useState } from "react";
import * as anchor from "@project-serum/anchor";
import Sidebar from "../components/views/Sidebar";
import Navbar from "../components/views/Navbar";
import Bears from '../components/assets/bears.svg'
import useWalletNfts from "../hooks/use-wallet-nfts";

import styled from "styled-components";
import { Container, Snackbar } from "@mui/material";
import Paper from "@mui/material/Paper";
import Alert from "@mui/lab/Alert";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Commitment,
  Connection,
  PublicKey,
  Transaction,
} from "@solana/web3.js";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import {
  awaitTransactionSignatureConfirmation,
  CANDY_MACHINE_PROGRAM,
  CandyMachineAccount,
  createAccountsForMint,
  getCandyMachineState,
  getCollectionPDA,
  mintOneToken,
  SetupState,
} from "../candy-machine-new";
import { AlertState, formatNumber, getAtaForMint, toDate } from "../utils";
import { MintCountdown } from "../MintCountdown";
import { MintButton } from "../MintButton";
import { GatewayProvider } from "@civic/solana-gateway-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { SolanaMobileWalletAdapterWalletName } from "@solana-mobile/wallet-adapter-mobile";

const ConnectButton = styled(WalletDialogButton)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: #fff;
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

const MintContainer = styled.div`
  background:#fff;
`; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
  network: WalletAdapterNetwork;
  error?: string;
}

const Mint = (props: HomeProps) => {
  const [minSidebar, setMinSiderBar] = useState(false);
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [increaseValue, setIncreaseValue] = useState(1);
  const [slimrevealcount, setSlimrevealcount] = useState(0);
	const { isLoadingWalletNfts, walletNfts, setWalletNfts, getWalletNfts, walletSlimeNfts, setWalletSlimeNfts, getWalletSlimeNfts, walletSlimeRevealNfts, setWalletSlimeRevealNfts, getWalletSlimeRevealNfts } = useWalletNfts();  
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const [isActive, setIsActive] = useState(false);
  const [endDate, setEndDate] = useState<Date>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [isWhitelistUser, setIsWhitelistUser] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const [isValidBalance, setIsValidBalance] = useState(false);
  const [discountPrice, setDiscountPrice] = useState<anchor.BN>();
  const [needTxnSplit, setNeedTxnSplit] = useState(true);
  const [setupTxn, setSetupTxn] = useState<SetupState>();

  const rpcUrl = props.rpcHost;
  const anchorWallet = useAnchorWallet();
  const { connect, connected, publicKey, wallet } = useWallet();
  const cluster = props.network;

  const increament = async() => {
		if(walletSlimeNfts.length >= increaseValue+1){
			setIncreaseValue(increaseValue+1);			
			console.log("increament called!: ", increaseValue+1);
		}
	}
  
  const sleep = (ms:any) => new Promise(r => setTimeout(r, ms));

	const decreament = () => {
		if(increaseValue > 1){
			setIncreaseValue(increaseValue-1);
			console.log("decreament called!: ", increaseValue-1);
		}
	}
  const refreshCandyMachineState = useCallback(
    async (commitment: Commitment = "confirmed") => {  
      await getWalletSlimeNfts();
      await getWalletSlimeRevealNfts();      
      if (!publicKey) {
        return;
      }
      if (props.error !== undefined) {
        setAlertState({
          open: true,
          message: props.error,
          severity: "error",
          hideDuration: null,
        });
        return;
      }

      const connection = new Connection(props.rpcHost, commitment);

      if (props.candyMachineId) {
        try {
          const cndy = await getCandyMachineState(
            anchorWallet as anchor.Wallet,
            props.candyMachineId,
            connection
          );
          console.log("Candy machine state: ", cndy);
          let active = cndy?.state.goLiveDate
            ? cndy?.state.goLiveDate.toNumber() < new Date().getTime() / 1000
            : false;
          let presale = false;

          // duplication of state to make sure we have the right values!
          let isWLUser = false;
          let userPrice = cndy.state.price;

          // whitelist mint?
          if (cndy?.state.whitelistMintSettings) {
            // is it a presale mint?
            if (
              cndy.state.whitelistMintSettings.presale &&
              (!cndy.state.goLiveDate ||
                cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
            ) {
              presale = true;
            }
            // is there a discount?
            if (cndy.state.whitelistMintSettings.discountPrice) {
              setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);
              userPrice = cndy.state.whitelistMintSettings.discountPrice;
            } else {
              setDiscountPrice(undefined);
              // when presale=false and discountPrice=null, mint is restricted
              // to whitelist users only
              if (!cndy.state.whitelistMintSettings.presale) {
                cndy.state.isWhitelistOnly = true;
              }
            }
            // retrieves the whitelist token
            const mint = new anchor.web3.PublicKey(
              cndy.state.whitelistMintSettings.mint
            );
            const token = (await getAtaForMint(mint, publicKey))[0];

            try {
              const balance = await connection.getTokenAccountBalance(token);
              isWLUser = parseInt(balance.value.amount) > 0;
              // only whitelist the user if the balance > 0
              setIsWhitelistUser(isWLUser);

              if (cndy.state.isWhitelistOnly) {
                active = isWLUser && (presale || active);
              }
            } catch (e) {
              setIsWhitelistUser(false);
              // no whitelist user, no mint
              if (cndy.state.isWhitelistOnly) {
                active = false;
              }
              console.log(
                "There was a problem fetching whitelist token balance"
              );
              console.log(e);
            }
          }
          userPrice = isWLUser ? userPrice : cndy.state.price;

          if (cndy?.state.tokenMint) {
            // retrieves the SPL token
            const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
            const token = (await getAtaForMint(mint, publicKey))[0];
            try {
              const balance = await connection.getTokenAccountBalance(token);

              const valid = new anchor.BN(balance.value.amount).gte(userPrice);

              // only allow user to mint if token balance >  the user if the balance > 0
              setIsValidBalance(valid);
              active = active && valid;
            } catch (e) {
              setIsValidBalance(false);
              active = false;
              // no whitelist user, no mint
              console.log("There was a problem fetching SPL token balance");
              console.log(e);
            }
          } else {
            const balance = new anchor.BN(
              await connection.getBalance(publicKey)
            );
            const valid = balance.gte(userPrice);
            setIsValidBalance(valid);
            active = active && valid;
          }

          // datetime to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.date) {
            setEndDate(toDate(cndy.state.endSettings.number));
            if (
              cndy.state.endSettings.number.toNumber() <
              new Date().getTime() / 1000
            ) {
              active = false;
            }
          }
          // amount to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.amount) {
            const limit = Math.min(
              cndy.state.endSettings.number.toNumber(),
              cndy.state.itemsAvailable
            );
            if (cndy.state.itemsRedeemed < limit) {
              setItemsRemaining(limit - cndy.state.itemsRedeemed);
            } else {
              setItemsRemaining(0);
              cndy.state.isSoldOut = true;
            }
          } else {
            setItemsRemaining(cndy.state.itemsRemaining);
          }

          if (cndy.state.isSoldOut) {
            active = false;
          }

          const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
          const collectionPDAAccount = await connection.getAccountInfo(
            collectionPDA
          );

          setIsActive((cndy.state.isActive = active));
          setIsPresale((cndy.state.isPresale = presale));
          setCandyMachine(cndy);

          const txnEstimate =
            892 +
            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
            (cndy.state.tokenMint ? 66 : 0) +
            (cndy.state.whitelistMintSettings ? 34 : 0) +
            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
            (cndy.state.gatekeeper ? 33 : 0) +
            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

          setNeedTxnSplit(txnEstimate > 1230);
        } catch (e) {
          if (e instanceof Error) {
            if (
              e.message === `Account does not exist ${props.candyMachineId}`
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value wrong in your .env file, or you are using the wrong RPC!`,
                severity: "error",
                hideDuration: null,
              });
            } else if (
              e.message.startsWith("failed to get info about account")
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
                severity: "error",
                hideDuration: null,
              });
            }
          } else {
            setAlertState({
              open: true,
              message: `${e}`,
              severity: "error",
              hideDuration: null,
            });
          }
          console.log(e);
        }
      } else {
        setAlertState({
          open: true,
          message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
          severity: "error",
          hideDuration: null,
        });
      }
    },
    [anchorWallet, props.candyMachineId, props.error, props.rpcHost]
  );

  const onMint = async (
    beforeTransactions: Transaction[] = [],
    afterTransactions: Transaction[] = []
  ) => {
    try {      
      for(let cnt=0;cnt<increaseValue;cnt++){
      setIsUserMinting(true);
      if (connected && candyMachine?.program && publicKey) {
        let setupMint: SetupState | undefined;
        if (needTxnSplit && setupTxn === undefined) {
          setAlertState({
            open: true,
            message: "Please sign account setup transaction",
            severity: "info",
          });
          setupMint = await createAccountsForMint(candyMachine, publicKey);
          let status: any = { err: true };
          if (setupMint.transaction) {
            status = await awaitTransactionSignatureConfirmation(
              setupMint.transaction,
              props.txTimeout,
              props.connection,
              true
            );
          }
          if (status && !status.err) {
            setSetupTxn(setupMint);
            setAlertState({
              open: true,
              message:
                "Setup transaction succeeded! Please sign minting transaction",
              severity: "info",
            });
          } else {
            setAlertState({
              open: true,
              message: "Mint failed! Please try again!",
              severity: "error",
            });
            setIsUserMinting(false);
            return;
          }
        } else {
          setAlertState({
            open: true,
            message: "Please sign minting transaction",
            severity: "info",
          });
        }

          const mintResult = await mintOneToken(
            candyMachine,
            publicKey,
            walletSlimeNfts[cnt],
            beforeTransactions,
            afterTransactions,
            setupMint ?? setupTxn
          );

          let status: any = { err: true };
          let metadataStatus = null;
          if (mintResult) {
            status = await awaitTransactionSignatureConfirmation(
              mintResult.mintTxId,
              props.txTimeout,
              props.connection,
              true
            );

            metadataStatus =
              await candyMachine.program.provider.connection.getAccountInfo(
                mintResult.metadataKey,
                "processed"
              );
            console.log("Metadata status: ", !!metadataStatus);
          }
          //await getWalletSlimeNfts();
          if (status && !status.err && metadataStatus) {
            // manual update since the refresh might not detect
            // the change immediately
            const remaining = itemsRemaining! - 1;
            setItemsRemaining(remaining);
            setIsActive((candyMachine.state.isActive = remaining > 0));
            candyMachine.state.isSoldOut = remaining === 0;
            setSetupTxn(undefined);
            setAlertState({
              open: true,
              message: "Congratulations! Mint succeeded!",
              severity: "success",
              hideDuration: 7000,
            });
            console.log("waiting...");
            if(cnt == 0)
              await sleep(15000);
            else
            await sleep(5000);
            console.log("completed...");
            await getWalletSlimeNfts();
            await getWalletSlimeRevealNfts();
            setSlimrevealcount(1);
            refreshCandyMachineState("processed");
            setIsUserMinting(false);
          } else if (status && !status.err) {
            setAlertState({
              open: true,
              message:
                "Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.",
              severity: "error",
              hideDuration: 8000,
            });
            await getWalletSlimeNfts();
            refreshCandyMachineState();
          } else {
            setAlertState({
              open: true,
              message: "Mint failed! Please try again!",
              severity: "error",
            });
            await getWalletSlimeNfts();
            refreshCandyMachineState();
          }
        }
      }
    } catch (error: any) {
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction timeout! Please try again.";
        } else if (error.message.indexOf("0x137")) {
          console.log(error);
          message = ``;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          console.log(error);
          message = ``;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
      // updates the candy machine state to reflect the latest
      // information on chain
      await getWalletSlimeNfts();
      refreshCandyMachineState();
    } finally {
      setIsUserMinting(false);
    }
  };

  const toggleMintButton = () => {
    let active = !isActive || isPresale;

    if (active) {
      if (candyMachine!.state.isWhitelistOnly && !isWhitelistUser) {
        active = false;
      }
      if (endDate && Date.now() >= endDate.getTime()) {
        active = false;
      }
    }

    if (
      isPresale &&
      candyMachine!.state.goLiveDate &&
      candyMachine!.state.goLiveDate.toNumber() <= new Date().getTime() / 1000
    ) {
      setIsPresale((candyMachine!.state.isPresale = false));
    }

    setIsActive((candyMachine!.state.isActive = active));
  };

  useEffect(() => {
    refreshCandyMachineState();    
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  useEffect(() => {
    (function loop() {
      setTimeout(() => {
        refreshCandyMachineState();
        loop();
      }, 20000);
    })();
  }, [refreshCandyMachineState]);

  return (
    <div className="flex h-full-screen">
			<Sidebar minSidebar={minSidebar} setMinSiderBar={setMinSiderBar} activeLink="mint" />
      <div className="w-full h-full">
				<Navbar setMinSiderBar={setMinSiderBar} minSidebar={minSidebar}  globalData={undefined}/>
        <div className="text-center">
					<h1 className="text-4xl mt-5">Mint the O5O NFT</h1>
					<h3>Burn the Slimes to get them out of the Hood</h3>
          { isUserMinting && slimrevealcount == 0 && (
                    <div>
                    <img src="/loading.gif" style={{width:"50px"}} className="w-1/3 h-1/3 mx-auto my-4"/> 
                      Processing your request, please do not refresh the page.
                    </div>                    
          )}
          { !isUserMinting && slimrevealcount == 0  && (
            <img src={Bears} className="w-1/3 h-1/3 mx-auto my-4"></img>
          )}                
          { walletSlimeRevealNfts.length > 0 && slimrevealcount > 0  &&(
              <video className="mx-auto mt-5" width="380px" height="auto" autoPlay controls loop>
              <source src={walletSlimeRevealNfts[walletSlimeRevealNfts.length-1].animation_url} type="video/mp4"/>
            </video>
          )}
					<h3>Select number of SLIMES to burn</h3>
					<h5>SLIMES in Wallet {walletSlimeNfts.length}</h5>
					<div className="flex justify-center mt-3">
						<div className="mr-4 p-1">
							<div className="border-2 px-5 py-2">
								<button type="button" onClick={decreament} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
									<span className="w-4 h-4" style={{fontSize:"24px"}}> - </span>
								</button>
								<span>{increaseValue}</span>
								<button type="button" onClick={increament} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center ml-5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <span className="w-4 h-4" style={{fontSize:"24px"}}> + </span>
								</button>
							</div>
							<h3 className="text-sm">Max {walletSlimeNfts.length}</h3>
						</div>
						<div className="p-1">
              {walletSlimeNfts.length > 0 ? (
                <MintButton
                candyMachine={candyMachine}
                isMinting={isUserMinting}
                setIsMinting={(val) => setIsUserMinting(val)}
                onMint={onMint}
                isActive={
                  isActive ||
                  (isPresale && isWhitelistUser && isValidBalance)
                }
              />
              ):(
                <button style={{backgroundColor:"lightgray", cursor:"default"}} disabled={true} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium sc-bdfBQB icCmPl css-sghohy-MuiButtonBase-root-MuiButton-root" type="button">MINT<span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>
              )}
						</div>
					</div>
					<h3 className="text-sm">Have fun enjoy the instant reveal after mint</h3>
				</div>
        <Container style={{ marginTop: 100 }}>
          {/* <Container maxWidth="xs" style={{ position: "relative" }}>
            <Paper
              style={{
                padding: 24,
                paddingBottom: 10,
                backgroundColor: "#fff",
                borderRadius: 0,
              }}
            >
              {!connected ? (
                <ConnectButton
                  onClick={(e) => {
                    if (
                      wallet?.adapter.name === SolanaMobileWalletAdapterWalletName
                    ) {
                      connect();
                      e.preventDefault();
                    }
                  }}
                >
                  Connect Wallet
                </ConnectButton>
              ) : (
                <>
                  {candyMachine && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      wrap="nowrap"
                    >
                      <Grid item xs={3}>
                        <Typography variant="body2" color="textSecondary">
                          Remaining
                        </Typography>
                        <Typography
                          variant="h6"
                          color="textPrimary"
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {`${itemsRemaining}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2" color="textSecondary">
                          {isWhitelistUser && discountPrice
                            ? "Discount Price"
                            : "Price"}
                        </Typography>
                        <Typography
                          variant="h6"
                          color="textPrimary"
                          style={{ fontWeight: "bold" }}
                        >
                          {isWhitelistUser && discountPrice
                            ? `◎ ${formatNumber.asNumber(discountPrice)}`
                            : `◎ ${formatNumber.asNumber(
                                candyMachine.state.price
                              )}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        {isActive && endDate && Date.now() < endDate.getTime() ? (
                          <>
                            <MintCountdown
                              key="endSettings"
                              date={getCountdownDate(candyMachine)}
                              style={{ justifyContent: "flex-end" }}
                              status="COMPLETED"
                              onComplete={toggleMintButton}
                            />
                            <Typography
                              variant="caption"
                              align="center"
                              display="block"
                              style={{ fontWeight: "bold" }}
                            >
                              TO END OF MINT
                            </Typography>
                          </>
                        ) : (
                          <>
                            <MintCountdown
                              key="goLive"
                              date={getCountdownDate(candyMachine)}
                              style={{ justifyContent: "flex-end" }}
                              status={
                                candyMachine?.state?.isSoldOut ||
                                (endDate && Date.now() > endDate.getTime())
                                  ? "COMPLETED"
                                  : isPresale
                                  ? "PRESALE"
                                  : "LIVE"
                              }
                              onComplete={toggleMintButton}
                            />
                            {isPresale &&
                              candyMachine.state.goLiveDate &&
                              candyMachine.state.goLiveDate.toNumber() >
                                new Date().getTime() / 1000 && (
                                <Typography
                                  variant="caption"
                                  align="center"
                                  display="block"
                                  style={{ fontWeight: "bold" }}
                                >
                                  UNTIL PUBLIC MINT
                                </Typography>
                              )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <MintContainer>
                    {candyMachine?.state.isActive &&
                    candyMachine?.state.gatekeeper &&
                    publicKey &&
                    anchorWallet?.signTransaction ? (
                      <GatewayProvider
                        wallet={{
                          publicKey:
                            publicKey || new PublicKey(CANDY_MACHINE_PROGRAM),
                          signTransaction: anchorWallet.signTransaction,
                        }}
                        gatekeeperNetwork={
                          candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                        }
                        clusterUrl={rpcUrl}
                        cluster={cluster}
                        options={{ autoShowModal: false }}
                      >
                        <MintButton
                          candyMachine={candyMachine}
                          isMinting={isUserMinting}
                          setIsMinting={(val) => setIsUserMinting(val)}
                          onMint={onMint}
                          isActive={
                            isActive ||
                            (isPresale && isWhitelistUser && isValidBalance)
                          }
                        />
                      </GatewayProvider>
                    ) : (
                      <MintButton
                        candyMachine={candyMachine}
                        isMinting={isUserMinting}
                        setIsMinting={(val) => setIsUserMinting(val)}
                        onMint={onMint}
                        isActive={
                          isActive ||
                          (isPresale && isWhitelistUser && isValidBalance)
                        }
                      />
                    )}
                  </MintContainer>
                </>
              )}
              <Typography
                variant="caption"
                align="center"
                display="block"
                style={{ marginTop: 7, color: "grey" }}
              >
                
              </Typography>
            </Paper>
          </Container> */}

          <Snackbar
            open={alertState.open}
            autoHideDuration={
              alertState.hideDuration === undefined ? 6000 : alertState.hideDuration
            }
            onClose={() => setAlertState({ ...alertState, open: false })}
          >
            <Alert
              onClose={() => setAlertState({ ...alertState, open: false })}
              severity={alertState.severity}
            >
              {alertState.message}
            </Alert>
          </Snackbar>
        </Container>
      </div>
    </div>
  );
};

const getCountdownDate = (
  candyMachine: CandyMachineAccount
): Date | undefined => {
  if (
    candyMachine.state.isActive &&
    candyMachine.state.endSettings?.endSettingType.date
  ) {
    return toDate(candyMachine.state.endSettings.number);
  }

  return toDate(
    candyMachine.state.goLiveDate
      ? candyMachine.state.goLiveDate
      : candyMachine.state.isPresale
      ? new anchor.BN(new Date().getTime() / 1000)
      : undefined
  );
};

export default Mint;
