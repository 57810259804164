export const NEXT_PUBLIC_SOLANA_NETWORK = 'devnet';
export const NEXT_PUBLIC_STAKE_CONTRACT_ID = 'Bnmuo5aGbvhwUGYkkZydyxgsyPNuXNL9GhkVH6XpKnu1';
export const GLOBAL_AUTHORITY_SEED = "global-authority";
export const USER_POOL_SEED = "user-pool";
export const USER_POOL_DATA_SEED = "user-pool-data";
export const REWARD_TOKEN = "HMNGJyiuqEKny6grmcJwbNLmBn3WYcH3rgmWjwSph7ks";
export const REWARD_TOKEN_DECIMALS = 1000000000;
export const BACKEND_URL = 'https://o5o-backend.herokuapp.com';
// export const BACKEND_URL = 'http://localhost:5000';

export const LIKE = 'Like';
export const COMMENT = 'Comment';
export const RETWEET = 'Retweet';
export const QUOTE = 'Quoted Tweet';
export const NEXT_PUBLIC_SOLANA_COL_CREATOR = 'DjzyKGdZPLd1xfs5bpBmgMbnro9msj9inr8T7deYvZ97';
export const NEXT_PUBLIC_SOLANA_REC_ADDRESS = '3e2cB5fjMhVq9kiHjTms6f4KFnZ639ftYcibHWR82vVk';
export const NEXT_PUBLIC_SOLANA_NFT_NAME = 'O5O SLIMES Reveal';
